<template>
  <div>
    <div class="card-layout">
      <div v-for="(shopGoods, s_index) in goodsList" :key="s_index">
        <div class="card-header" v-if="orderType!=90">
          <!-- <van-icon name="shop-o" color="#9b9b9b" /> -->
          <img src="~@/assets/common/store_icon@2x.png" class="shop-icon" />
          <div class="title">供应商：{{ shopGoods.shop_name }}</div>
        </div>
        <!-- 商品详情 -->
        <OrderDetails :orderType='orderType' v-if="orderType!=90" :shopGoods="shopGoods.goods_list"></OrderDetails>

        <OrderDetails :orderType='orderType' v-else :shopGoods="shopGoods"></OrderDetails>

        <div class="operation">
         <!-- <van-cell-group>
            <van-field v-model="value" label="配送方式" readonly />
          </van-cell-group> -->
          <van-field
            v-model="message[s_index].msg"
            rows="2"
            autosize
            :label="orderType!=90?'给卖家留言':'备注'"
            type="text"
            :placeholder="orderType!=90?'建议留言前先与商家沟通':'请输入备注'"
            show-word-limit
          />
        </div>
      </div>
    </div>

    <div class="summary-price" v-if="orderType === 90">
      <div class="sum-up-price layout" @click="showTime=true">
        <span>配送时间</span>
        <span>{{deliveryTime?deliveryTime:'选择时间'}}</span>
      </div>

    </div>

    <!-- 总价格 -->
    <div class="summary-price">
      <div class="sum-up-price layout">
        <span>商品总价</span>
        <span>￥{{ twoNum(totalInfo.total_price) }}</span>
      </div>
      <div class="send-price layout">
        <span>配送费用</span>
        <span class="color">￥{{ twoNum(totalInfo.total_postage) }}</span>
      </div>
      <div
        class="spend-gold"
        @click="onClick"
        v-if="totalInfo.max_expense * 1 > 0 && orderType !== 5 && false"
      >
        <div>
          <span>消费金</span>
          <span>（本次最多抵扣￥{{ twoNum(totalInfo.max_expense) }}）</span>
        </div>
        <div>
          <div class="spend-gold-color">-￥{{ twoNum(expenseMoney) }}</div>
          <van-icon name="arrow" class="color" />
        </div>
      </div>
<!--      <div  class="spend-gold" @click="openDiscounts" v-if="orderType !== 5">-->
      <!-- <div  class="spend-gold" @click="openDiscounts" v-if="false">
        <div>优惠券</div>
        <div>
          <div class="spend-gold-color">-￥{{ twoNum(couponMoney) }}</div>
          <van-icon name="arrow" class="color" />
        </div>
      </div> -->
      <div class="sum-price layout">
        <span>合计</span>
        <span class="color">￥{{ twoNum(totalMoney) }}</span>
      </div>
    </div>
    <ActionSheet
      :isShow="isShow"
      @setShowSheet="setShowSheet"
      :totalEexpense="totalInfo.max_expense"
      @getExpenseMoney="setExpenseMoney"
    ></ActionSheet>
    <DiscountsPopup
      ref="couponList"
      :couponList="couponList"
      :oldTotalMoney="oldTotalMoney"
      @setCoupon="setCoupon"
    ></DiscountsPopup>


    <van-popup v-model="showTime" position="bottom" :style="{ height: '45%' }">
      <div class="pop-tiem">
        <div class="pop-tiem-title">选择送达时间</div>
        <div class="pop-box">
          <div class="pop-left-list">
            <div :class="cDateType===i?'s-s1':''" v-for="(item,i) in dList" :key="i" @click="selectDate(i,item)">
              {{item}}
            </div>
          </div>
          <div class="pop-right-list">
            <div v-for="(item,i) in hList" :key="i" @click="selectTiem(i,item)">
              {{item}}
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import OrderDetails from "./order-details";
import ActionSheet from "./action-sheet";
import DiscountsPopup from "../../../discounts/components/order-popup/order-popup";
import { getShoppingGoods, getTotalPrice } from "@/api/shopping";
import { getUseCouponList } from "@/api/order";

// import { DatetimePicker } from 'vant';
import { robGoods,robTotal} from "@/api/rob.js";


export default {
  name: "order-card",
  components: {
    OrderDetails,
    ActionSheet,
    DiscountsPopup,
  },
  props: ["shoppingIds", "orderType"],
  data() {
    return {
      showTime:false,
      tiemTitle:"",
      dList:[],
      hList:[],
      cDateType:0,
      cTimeType:'',
      dDate:"",
      tDate:"",
      deliveryTime:'',


      value: "普通快递",
      isShow: false,
      message: [],
      goodsList: [],
      totalInfo: { total_price: 0, total_postage: 0, total_expense: 0 },
      totalMoney: 0,
      oldTotalMoney: 0,
      expenseMoney: 0,
      couponMoney: 0,
      couponId: 0,
      couponInfo: { money: 0 },
      couponList: { coupon_list: [], un_coupon: [] },
      addressInfo: { province: 0, city: 0, area: 0 },
      discountsShow: false,
    };
  },
  watch: {
    "_props.addressId": function () {},
  },
  mounted() {
    this.getShoppingGoods();
    this.getTotalPrice();

    this.dList = [this.setDateFun(0),this.setDateFun(1),this.setDateFun(2)];
    this.dDate = this.dList[0]
    this.setTiemFun(this.cDateType)
  },
  methods: {
    setDateFun(type){
      let str = '';
      var cTime = new Date();
      if(type>0){
        cTime.setDate(cTime.getDate() + (type*1));
      }
      let y = cTime.getFullYear();
      let m = cTime.getMonth() + 1;
      let d = cTime.getDate();
      return str = y + '/' + (m<10?'0'+m:m) + '/' + (d<10?'0'+d:d);
    },
    setTiemFun(type){
      this.hList = [
        "08:00-10:00",
        "10:00-12:00",
        "12:00-14:00",
        "14:00-16:00",
        "16:00-18:00",
        "18:00-20:00",]
      if(type === 0){
        var time = new Date();
        var h = time.getHours();
        if(h>=10&&h<=12){
          this.hList.shift()
        }else if(h>=12&&h<=14){
          this.hList.splice(0, 2)
        }else if(h>=14&&h<=16){
          this.hList.splice(0, 3)
        }else if(h>=16&&h<=18){
          this.hList.splice(0, 4)
        }else if(h>=18&&h<=20){
          this.hList.splice(0, 5)
        }else if(h<8&&h>20){
          this.hList = []
        }
      }
    },
    selectDate(type,item){
      if(this.cDateType!=type){
        this.cDateType = type;
        this.dDate = item;
        this.setTiemFun(this.cDateType);
      }
    },
    selectTiem(i,item){
      if(this.cTimeType!=i||!this.cTimeType){
        this.cTimeType=i;
        this.tDate = item;
        this.deliveryTime = this.dDate + ' ' + this.tDate;
        this.showTime = false;
      }
    },

    openDiscounts() {
      this.$refs.couponList.showCoupon();
    },
    closePopup() {
      this.discountsShow = false;
    },
    buyNum(value, detail) {
      this.totalMoney =
        this.totalInfo.total_price * value +
        this.totalInfo.total_postage -
        this.expenseMoney -
        this.couponMoney;
    },
    setAddressInfo(addressInfo) {
      this.addressInfo = addressInfo;
      this.getTotalPrice();
    },
    onClick() {
      this.isShow = !this.isShow;
    },
    setShowSheet() {
      this.isShow = false;
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    setExpenseMoney(expenseMoney) {
      this.expenseMoney = expenseMoney;
      this.setTotalMoney();
    },
    setTotalMoney() {
      this.totalMoney =
        this.totalInfo.total_price +
        this.totalInfo.total_postage -
        this.expenseMoney;
      this.oldTotalMoney = this.totalMoney;
      if (this.totalMoney < this.couponMoney) {
        this.couponMoney = this.totalMoney;
      }
      this.totalMoney -= this.couponMoney;

      if (this.totalMoney < 0) {
        this.totalMoney = 0;
      }
      this.$emit("setTotalMoney", this.totalMoney);
      this.$emit("setExpenseMoney", this.expenseMoney);
      //计算订单邮费
      this.goodsList.forEach((shop, shopKey) => {
        if (this.totalInfo.shop_list[shop.shop_id] !== undefined) {
          shop.goods_list.forEach((goods, goodsKey) => {
            if (
              this.totalInfo.shop_list[shop.shop_id].goods_postage[
                goods.spec_id
              ] !== undefined
            ) {
              goods.freight_money =
                this.totalInfo.shop_list[shop.shop_id].goods_postage[
                  goods.spec_id
                ];
            }
          });
        }
      });
    },
    setCoupon(couponIndex) {
      if (couponIndex < 0) {
        this.couponInfo = { money: 0, id: 0 };
        this.couponMoney = 0;
        this.$emit("setCouponId", this.couponInfo.id);
        this.setTotalMoney();
      } else {
        this.couponInfo = this.couponList.coupon_list[couponIndex];
        this.couponMoney = this.couponInfo.money;
        this.$emit("setCouponId", this.couponInfo.id);
        this.setTotalMoney();
      }
    },
    async getUseCouponList() {
      const ret = await getUseCouponList({ shopping_ids: this.shoppingIds });
      if (ret.code * 1 === 1) {
        this.couponList = ret.data;
        console.log(this.couponList);
        if (this.couponList.coupon_list.length > 0) {
          this.setCoupon(0);
        }
      }
    },
    async getShoppingGoods() {

      if(this.orderType === 90){
        robGoods({shopping_ids:this.shoppingIds}).then((ret)=>{
          if (ret.code * 1 == 1) {
            for (let i = 0; i < ret.data.length; ++i) {
              this.message.push({ msg: "" });
            }
            this.goodsList = ret.data;
          }
        })
      }else{
        const ret = await getShoppingGoods({ shopping_ids: this.shoppingIds });
        if (ret.code * 1 === 1) {
          for (let i = 0; i < ret.data.length; ++i) {
            this.message.push({ msg: "" });
          }
          this.goodsList = ret.data;
        }
      }

    },
    async getTotalPrice() {
      if(this.orderType!=90){
        const ret = await getTotalPrice({
          shopping_ids: this.shoppingIds,
          province_id: this.addressInfo.province,
          city_id: this.addressInfo.city,
        });
        if (ret.code * 1 === 1) {
          this.totalInfo = ret.data;
          this.goodsList.forEach((shop, shopKey) => {
            console.log(this.totalInfo);
            if (this.totalInfo.shop_list[shop.shop_id] !== undefined) {
              shop.goods_list.forEach((goods, goodsKey) => {
                if (
                  this.totalInfo.shop_list[shop.shop_id].goods_postage[
                    goods.spec_id
                  ] !== undefined
                ) {
                  goods.freight_money =
                    this.totalInfo.shop_list[shop.shop_id].goods_postage[
                      goods.spec_id
                    ];
                }
              });
            }
          });
          // this.getUseCouponList();
          this.setTotalMoney();
        }
      }else{
        robTotal({shopping_ids: this.shoppingIds,}).then((res)=>{
          if(res.code === 1){
            this.totalInfo = res.data
            this.totalMoney = res.data.total_price * 1 + res.data.total_postage * 1
            this.$emit("setTotalMoney", this.totalMoney);
          }
        })
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 16px 16px 160px;
}

.pop-tiem{
  .pop-tiem-title{
    width: 100%;
    height: 65px;
    line-height: 65px;
    font-size: 18px;
    text-align: center;
    border-bottom: 1px solid #eee;
  }
  .pop-box{
    display: flex;
    min-height: 100px;
   .pop-left-list{
     width: 106px;
     background: #FAFAFA;
     border-right: 1px solid #eee;
     div{
       width: 106px;
       height: 52px;
       border-bottom: 1px solid #eee;
       font-size: 14px;
       text-align: center;
       line-height: 52px;
     }
     .s-s1{
       color: #ED301D;
       background: #fff !important;
     }
   }
   .pop-right-list{
     flex: 1;
     div{
       width: 100%;
       height: 52px;
       border-bottom: 1px solid #eee;
       font-size: 14px;
       text-align: center;
       line-height: 52px;
     }
     .s-s2{
       color: #ED301D;
     }
   }
  }
}

.summary-price {
  margin-top: 12px;
  padding: 12px;
  // margin-bottom: 73px;
  background-color: #fff;
  border-radius: 16px;
}

.card-layout {
  padding: 12px;
  padding-bottom: 0;
  margin-top: 12px;
  border-radius: 15px;
  background-color: #fff;
}

.card-header {
  display: flex;
  justify-content: start;
  align-items: center;
  font-size: 18px;
  font-weight: 500;

  .shop-icon {
    display: block;
    width: 21px;
    height: 21px;
  }

  .title {
    margin-left: 4px;
    font-size: 17px;
  }
}

/deep/ .operation {
  .van-cell {
    padding: 10px 0;

    .van-field__label {
      font-size: 16px;
      color: #000;
    }
  }
}

// 总价格
.layout {
  display: flex;
  justify-content: space-between;
  padding: 6px 16px 6px 0;
  font-size: 17px;
}

// .send-price {
//   .color {
//   }
// }

.spend-gold {
  display: flex;
  justify-content: space-between;
  padding: 6px 0 6px 0;
  font-size: 17px;

  div {
    display: flex;
    align-items: center;
  }

  align-items: center;

  .spend-gold-color {
    color: #fd6d1f;
  }
}

.sum-price {
  .color {
    color: #ed301d;
  }
}
</style>
