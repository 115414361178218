<template>
  <van-action-sheet v-model="isShow" title="使用消费金">
    <div class="content">
      <div class="layout">
        <span>当前消费金</span>
        <span>￥{{ twoNum(moneyInfo.consume_money) }}</span>
      </div>
      <div class="layout xiao-fei-jin">
        <span>本次最多可用消费金</span>
        <span>￥{{ twoNum(totalEexpense) }}</span>
      </div>
      <div class="layout-gold">使用消费金</div>
      <div class="input-box">
        ￥<input type="number" class="input" v-model="expenseMoney" />
      </div>
      <van-button
        block
        round
        color="linear-gradient(to right, #FC5656 , #F70606)"
        class="button-position"
        @click="setShow"
      >
        确认使用
      </van-button>
    </div>
  </van-action-sheet>
</template>

<script>
import { getMoneyInfo } from "@/api/member";

export default {
  name: "action-sheet",
  props: ["isShow", "totalEexpense"],
  data() {
    return {
      moneyInfo: { consume_money: 0 },
      expenseMoney: "",
    };
  },
  mounted() {
    this.getMoneyInfo();
  },
  methods: {
    setShow() {
      this.$emit("setShowSheet");
      this.$emit("getExpenseMoney", this.expenseMoney);
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    async getMoneyInfo() {
      const ret = await getMoneyInfo();
      if (ret.code * 1 === 1) {
        this.moneyInfo = ret.data;
      }
    },
  },
  watch: {
    "_props.totalInfo.total_expense": function () {
      this.totalEexpense = this._props.totalInfo.total_expense;
    },
    expenseMoney: function () {
      if (isNaN(this.expenseMoney)) {
        this.expenseMoney = "";
      }
      if (this.expenseMoney != "") {
        if (this.expenseMoney < 0) {
          this.expenseMoney = "";
        } else {
          if (this.expenseMoney * 1 > this.totalEexpense * 1) {
            this.expenseMoney = this.totalEexpense;
          }
          if (this.expenseMoney * 1 > this.moneyInfo.consume_money * 1) {
            this.expenseMoney = this.moneyInfo.consume_money;
          }
        }
      }
    },
  },
};
</script>;

<style lang="less" scoped>
.content {
  padding: 16px 16px 3px 16px;
}

.layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  margin-bottom: 33px;
  span {
    &:nth-child(2) {
      color: #ff0000 !important ;
    }
  }
}

.input {
  width: 78px;
  height: 28px;
}

.content[data-v-36830e4c] {
  padding: 16px 16px 0px 16px;
}

.button-position {
  margin: 241px 0 0 0;
}
/deep/.van-action-sheet__header {
  font-size: 19px;
}
.layout-gold {
  font-size: 15px;
  color: #524f4f;
  margin-bottom: 33px;
}
.input-box {
  font-size: 18px;
  margin-top: 20px;

  input {
    width: 80%;
    border: none;
    margin-left: 10px;
    font-size: 35px;
    padding-bottom: 11px;
    border-bottom: 1px solid #e4e4e4;
  }
}
</style>
