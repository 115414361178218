<template>
  <div>
    <!-- 订单详情 -->
    <div class="" v-if="orderType!=90">
      <div
        class="order-details"
        v-for="(goods, g_index) in shopGoods"
        :key="g_index"
      >
        <div class="img-box">
          <img class="commodity-img" :src="goods.goods_img" alt="" />
        </div>
        <div class="commodity-info">
          <div>
            <div class="title-info">
              <span>{{ goods.goods_name }}</span>
              <span>￥{{ twoNum(goods.spec_price) }}</span>
            </div>
            <div class="specification">
              <div class="specification-info">
                <p>{{ goods.item_name }}</p>
              </div>
              <div class="number-of">x{{ goods.number }}</div>
            </div>
          </div>
          <div class="tag-box">
            <span class="tag" v-if="goods.freight_money * 1 <= 0">免运费</span>
            <span class="tag" v-else>
              运费：{{ twoNum(goods.freight_money) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    
    <div class="" v-else>
      <div
        class="order-details"
      >
        <div class="img-box">
          <img class="commodity-img" :src="shopGoods.goods_img" alt="" />
        </div>
        <div class="commodity-info">
          <div>
            <div class="title-info">
              <span>{{ shopGoods.goods_name }}</span>
              <span>￥{{ twoNum(shopGoods.spec_price) }}</span>
            </div>
            <div class="specification">
              <div class="specification-info">
                <p>{{ shopGoods.item_name }}</p>
              </div>
              <div class="number-of">x{{ shopGoods.number }}</div>
            </div>
          </div>
          <!-- <div class="tag-box">
            <span class="tag" v-if="goods.freight_money * 1 <= 0">免运费</span>
            <span class="tag" v-else>
              运费：{{ twoNum(goods.freight_money) }}
            </span>
          </div> -->
        </div>
      </div>
    </div>
    

  </div>
</template>

<script>
export default {
  name: "order-details",
  props: ["shopGoods","orderType"],
  data() {
    return {};
  },
  methods: {
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
  },
};
</script>

<style lang="less" scoped>
// 商品详情
.order-details {
  padding: 14px 0;
  display: flex;
  justify-content: start;
  font-size: 16px;
  .img-box {
    width: 110px;
    flex-shrink: 0;
    border-radius: 10px;
    overflow: hidden;
    .commodity-img {
      display: block;
      width: 110px;
      height: 110px;
    }
  }
}
.commodity-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10px;
  width: 100%;
  .tag-box {
    .tag {
      text-align: center;
      border: 1px solid #ed301d;
      color: #ed301d;
      border-radius: 6px;
      padding: 2px 8px;
      font-size: 12px;
    }
  }
  .title-info {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    div {
      &:last-child {
        font-weight: 500;
      }
    }
  }
  .specification {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #aaaaaa;
    .specification-info {
      p {
        margin: 10px 30px 0 0;
        background-color: #f5f5f5;
        padding: 4px 10px;
        border-radius: 4px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .number-of {
      font-size: 14px;
    }
  }
}
</style>
