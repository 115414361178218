<template>
  <div class="create-order-box">
    <div class="create-order-box-1">
      <NavBar class="nav-bar">
        <div slot="title">确认订单</div>
      </NavBar>
      <!-- 地址选择 -->
      <AddressNav
        :addressId="addressId"
        @getAddressId="setAddressId"
      ></AddressNav>
      <!-- 订单详情 -->
      <OrderCard
        ref="getOrderItem"
        :shoppingIds="shoppingIds"
        :orderType="orderType"
        @setTotalMoney="setTotalMoney"
        @setCouponId="setCouponId"
        @setExpenseMoney="setExpenseMoney"
      ></OrderCard>
    </div>
    <div class="create-order-box-2">
      <!-- 底部提交订单 -->
      <van-submit-bar
        :price="totalMoney"
        :class="{'verify-no-pay':!isVerifyPay}"
        button-text="提交订单"
        @submit="createOrder"
      >
      </van-submit-bar>
    </div>

    <PayShade
      v-if="isPay"
      :moneyInfo="moneyInfo"
      :orderId="orderId"
      :showPopup="isPay"
      @closeOverlay="closeOverlay"
    ></PayShade>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import PayShade from "@/components/pay-shade/pay-shade";
import AddressNav from "./moudles/address-nav";
import OrderCard from "./moudles/order-card";
import { getMoneyInfo } from "@/api/member";
import { createOrder, setOrderPay } from "@/api/order";
import { Toast } from "vant";
import { createSeckillOrder } from "@/api/seckill";
import { createNewUserOrder } from "@/api/NewUser.js";
import {verifyGoodsArea} from "@/api/shopping";

import { robCreate } from "@/api/rob.js";


export default {
  name: "submit-order",
  components: {
    AddressNav,
    OrderCard,
    PayShade,
    NavBar,
  },
  data() {
    return {
      checked: true,
      isPay: false,
      showPopup: false,
      radio: "1",
      passStr: "",
      showKeyboard: true,
      show: false,
      isFocus: true,
      orderType:
        this.$route.query.orderType === undefined
          ? "1"
          : this.$route.query.orderType * 1,
      shoppingIds:
        this.$route.query.shoppingIds === undefined
          ? "0"
          : this.$route.query.shoppingIds,
      addressId:
        this.$route.query.addressId === undefined
          ? "0"
          : this.$route.query.addressId,
      seckillId:
        this.$route.query.seckillId === undefined
          ? "0"
          : this.$route.query.seckillId,
      totalMoney: 0,
      expenseMoney: 0,
      couponId: 0,
      payMoney: "",
      moneyInfo: { money: 0 },
      isAjax: false,
      addressInfo: {
        id: 0,
      },
      orderId: 0,
      payInfo: {
        order_sn: "",
        total_money: "0",
      },
      payType: 1,
      isVerifyPay:false
    };
  },
  watch: {
    payMoney: function () {
      if (isNaN(this.payMoney)) {
        this.payMoney = "";
      }
      if (this.payMoney != "") {
        if (this.payMoney < 0) {
          this.payMoney = "";
        } else {
          if (this.payMoney * 1 >= this.payInfo.total_money * 1) {
            this.payMoney = this.payInfo.total_money;
            this.payType = 0;
          } else {
            this.payType = 1;
          }
          if (this.payMoney * 1 > this.moneyInfo.money * 1) {
            this.payMoney = this.moneyInfo.money;
          }
          // this.payMoney=this.payMoney.toFixed(2);
        }
      }
    },
  },
  mounted() {
    console.log("orderType=========", this.orderType);
    // console.log("create-order------------", this.orderType);
    this.getMoneyInfo();
  },
  methods: {
    setCouponId(couponId) {
      this.couponId = couponId;
    },
    closeOverlay() {
      // 关闭遮罩层自定义事件方法
      this.isPay = false;
      if (this.orderType * 1 === 1 || this.orderType * 1 === 4) {
        this.$router.replace({
          path: "/all-order",
          query: {
            orderType: 1,
          },
        });
      } else if (this.orderType * 1 === 2) {
        this.$router.replace({
          path: "/purchases-order",
          query: {
            orderType: 2,
          },
        });
      } else {
        this.$router.replace({
          path: "/all-order",
          query: {
            orderType: 3,
          },
        });
      }
      // 1普通
      // 2进货
      // 3公益
    },
    delPass() {
      //密码清除
      let passNum = this.passStr.length;
      if (passNum > 1) {
        this.passStr = this.passStr.substr(0, passNum - 1);
      } else {
        this.passStr = "";
      }
    },
    setPass(key) {
      //设置密码
      if (this.passStr.length < 6) {
        this.passStr += key;
      }
      if (this.passStr.length == 6) {
        //调用支付
        this.setOrderPay();
      }
    },
    goToPaySuccess() {
      this.$router.push({
        name: "pay-success",
        query: {
          orderId: this.orderId,
          payMoney: this.payMoney,
          orderType: this.orderType,
        },
      });
    },
    async setOrderPay() {
      //订单支付
      if (this.isAjax) {
        return false;
      }
      this.isAjax = true;
      const ret = await setOrderPay({
        balance_money: this.payMoney,
        order_id: this.orderId,
        pay_pass: this.passStr,
        pay_type: this.payType,
      });
      if (ret.code * 1 == 1) {
        if (ret.data.pay_status) {
          //跳转到支付成功页面
          this.goToPaySuccess();
        } else {
          //调用对应模块的支付
        }
      }
      this.isAjax = false;
    },
    async createOrder() {
      // 创建订单
      this.showPopup = true;
      if (this.addressInfo.id * 1 <= 0) {
        Toast.fail("请选择收货地址");
        return false;
      }
      if(!this.isVerifyPay){
        Toast.fail("此区域暂不配送");
        return false;
      }
      if (this.shoppingIds == "0") {
        Toast.fail("商品信息异常");
        return false;
      }

      if(this.orderType != 90){
        if (this.isAjax) {
          return false;
        }
        this.isAjax = true;
        if (this.orderType * 1 === 4) {
          const ret = await createSeckillOrder({
            shopping_ids: this.shoppingIds,
            address_id: this.addressInfo.id,
            coupon_id: this.couponId,
            seckill_id: this.seckillId,
            consume_money: this.expenseMoney,
            note: this.$refs.getOrderItem.message,
          });
          if (ret.code * 1 == 1) {
            this.orderId = ret.data.order_id;
            this.getOrderPayInfo();
          } else {
            Toast.fail(ret.msg);
          }
          this.isAjax = false;
        } else if (this.orderType * 1 === 5) {
          const ret = await createNewUserOrder({
            shopping_ids: this.shoppingIds,
            address_id: this.addressInfo.id,
            note: this.$refs.getOrderItem.message,
          });
          if (ret.code === 1) {
            this.orderId = ret.data.order_id;
            this.getOrderPayInfo();
          } else {
            Toast.fail(ret.msg);
          }
          this.isAjax = false;
        } else {
          const ret = await createOrder({
            shopping_ids: this.shoppingIds,
            address_id: this.addressInfo.id,
            coupon_id: this.couponId,
            consume_money: this.expenseMoney,
            note: this.$refs.getOrderItem.message,
          });
          if (ret.code * 1 == 1) {
            this.orderId = ret.data.order_id;
            this.getOrderPayInfo();
          } else {
            Toast.fail(ret.msg);
          }
          this.isAjax = false;
        }
      }else{
        if (this.addressInfo.id * 1 <= 0) {
          Toast.fail("请选择收货地址");
          return false;
        }
        if (this.shoppingIds == "0") {
          Toast.fail("商品信息异常");
          return false;
        }
        if (!this.$refs.getOrderItem.deliveryTime) {
          Toast.fail("请选择送达时间");
          return false;
        }
        if (this.isAjax) {
          return false;
        }
        this.isAjax = true;

        robCreate({
          shopping_ids: this.shoppingIds,
          address_id: this.addressInfo.id,
          note: this.$refs.getOrderItem.message[0].msg,
          delivery_time:this.$refs.getOrderItem.deliveryTime,
        }).then((res)=>{
          if(res.code === 1){
            this.orderId = res.data.order_id;
            this.getOrderPayInfo();
          }else{
            Toast.fail(res.msg);
            this.isAjax = false;
          }
        })
      }
    },
    async getOrderPayInfo() {
      //获取订单支付信息
      this.isPay = true;
      // const ret = await getOrderPayInfo({order_id: this.orderId});
      // if (ret.code * 1 === 1) {
      //   this.payInfo = ret.data;
      //   this.isPay = true;
      // }
    },
    async getMoneyInfo() {
      //获取会员资金信息
      const ret = await getMoneyInfo();
      if (ret.code * 1 === 1) {
        this.moneyInfo = ret.data;
      }
    },
    twoNum(num) {
      // 获取两位小数
      return (num * 1).toFixed(2);
    },
    setExpenseMoney(expenseMoney) {
      this.expenseMoney = expenseMoney;
    },
    setTotalMoney(totalMoney) {
      this.totalMoney = totalMoney * 100;
    },
    onFocus() {
      if (this.isFocus) {
        this.showKeyboard = true;
        this.show = true;
      }
    },
    setAddressId(addressInfo) {
      console.log(addressInfo);
      this.addressInfo = addressInfo;
      this.$refs.getOrderItem.setAddressInfo(this.addressInfo);
      if(this.addressInfo.area){
        this.verifyGoodsArea()
      }
    },
    verifyGoodsArea(){
      verifyGoodsArea({
        shopping_ids: this.shoppingIds,
        area_id: this.addressInfo.area,
      }).then((res)=>{
          this.isVerifyPay = res.code === 1;
      })
    }
  },
};
</script>

<style lang="less" scoped>
.create-order-box{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .create-order-box-1{
    width: 100%;
    flex: 1;
    overflow-y: scroll;
  }
  .create-order-box-2{
    height: 70px;
  }
}


.verify-no-pay{
  .van-submit-bar__button{
    opacity: 0.6;
  }
}
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid rgb(243, 243, 243);
}

.pay-details-layout {
  padding: 12px 12px 12px 12px;
}
.van-submit-bar__button {
  width: 116px;
  height: 52px;

}
.van-submit-bar__bar {
  height: 63px;
}
.van-cell__title {
  display: flex;
  align-items: center;
}
.van-button__text {
  font-size: 18px;
}
.wechat-icon {
  margin-right: 10px;
}

.shop-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .shop-title {
    font-size: 14px;
    color: #6f6f6f;
  }

  div {
    font-size: 30px;
    font-weight: 600;
  }
}

.pay-info {
  .lines {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 6px 24px 6px 0;

    .right-btn-icon {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .lines-pay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0px 6px 0;

    .fillIn-the-input {
      width: 78px;
      height: 28px;
    }
  }
}

.button-ok {
  margin-top: 180px;
}

// 密码弹出层
.password-layout {
  padding: 12px;
}

.enter-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 320px;

  .password-title {
    color: #6f6f6f;
    font-size: 22px;
  }

  .pay-price {
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0 40px 0;
  }
}
</style>
