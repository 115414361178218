<template>
  <div>
    <div class="address-layout" @click="setAddress">
      <van-icon name="location-o" size="20" color="#9B9B9B" />
      <div class="contact-two">
        <div class="contact" v-if="addressInfo.id * 1 <= 0">
          <span class="name">请选择您的地址</span>
        </div>
        <div class="contact" v-else>
          <span class="name">{{ addressInfo.consignee }}</span>
          <span class="phone">{{ addressInfo.phone }}</span>
        </div>
        <div class="address-details">
          <div class="structure">
            <div class="address" v-if="addressInfo.id * 1 > 0">
              {{ addressInfo.city_names }}{{ addressInfo.address }}
            </div>
            <div class="address address-no" v-else>请选择你的收货地址</div>
          </div>
        </div>
      </div>
      <van-icon name="arrow" color="#9B9B9B" size="16" class="icon-address" />
    </div>
    <!--收货地址修改-->
    <AddressList
      v-if="showAddress"
      ref="addressList"
      :orderType="1"
      :isRadio="true"
      @setAddressInfo="setAddressInfo"
      @closePage="closePage"
    ></AddressList>
  </div>
</template>

<script>
import AddressList from "@/pages/my/address-list/address-list";
import { getAddressInfo } from "@/api/member";

export default {
  name: "address-nav",
  props: ["addressId", "shoppingIds"],
  components: {
    AddressList,
  },
  data() {
    return {
      addressInfo: {},
      showAddress: false,
    };
  },
  mounted() {
    this.getAddressInfo();
  },
  methods: {
    closePage() {
      this.getAddressInfo();
      this.showAddress = false;
      this.$emit("navShow", true);
    },
    setAddressInfo(Ainfo) {
      // this.addressInfo = Ainfo;
      // this.$emit("getAddressId", this.addressInfo);
      this.getAddressInfo(Ainfo.id);
      this.showAddress = false;
    },
    setAddress() {
      //选择收货地址
      this.showAddress = true;
      // this.$nextTick(() => {
      //   this.$refs.addressList.addressRadio(this.addressInfo.id);
      // });
      localStorage.setItem("ADDRESS_ID", this.addressInfo.id);
      this.$emit("navShow", false);
    },
    async getAddressInfo(id) {
      const ret = await getAddressInfo({ id: id });
      if (ret.code * 1 === 1) {
        this.addressInfo = ret.data;
        this.$emit("getAddressId", this.addressInfo);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.address-layout {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 17px 12px 10px 12px;
  margin: 53px 0 10px 0;
  background-color: #fff;
  border-radius: 0 0px 15px 15px;
  font-size: 18px;
  box-sizing: border-box;
  .icon-address {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
  .contact-two {
    display: flex;
    flex-direction: column;
  }
  .contact {
    padding: 0 18px;
    .name {
      font-size: 17px;
      margin-right: 10px;
      font-weight: 500;
    }
    .phone {
      font-size: 18px;
      color: #000;
    }
  }

  .address-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6f6f6f;
    font-size: 16px;
    .structure {
      display: flex;
      align-items: center;
    }
    .address {
      padding: 0 18px;
    }

    .address-no {
      margin-right: 170px;
    }
  }
}
</style>
