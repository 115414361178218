<template>
  <van-popup v-model="show" :duration="0">
    <div class="order-popup">
      <div class="nav">
        <van-icon name="arrow-left" class="arrow-left" @click="closePopup" />
        <span>优惠券</span>
      </div>
      <div class="use-tab">
        <div class="tab" @click="setType(1)">
          <span :class="{ 'title-color': activeColor === 1 }">可以使用</span>
          <div class="tab-border" v-if="activeColor === 1"></div>
        </div>
        <div class="tab" @click="setType(2)">
          <span :class="{ 'title-color': activeColor === 2 }">不能使用</span>
          <div class="tab-border" v-if="activeColor === 2"></div>
        </div>
      </div>
      <ul class="discounts-content" v-if="list.length > 0">
        <van-checkbox-group
          v-model="result"
          checked-color="#ED301D"
          shape="round"
        >
          <li class="list" v-for="(item, index) in list" :key="index">
            <img
              v-if="item.is_day * 1 === 1"
              src="@/assets/discounts/duetoday@2x.png"
              class="expire-icon"
            />
            <div class="shade" v-if="activeColor === 2">
              <div class="cause-location" v-if="item.msg">
                <div>优惠券不可用原因</div>
                <div>{{ item.msg }}</div>
              </div>
            </div>
            <div class="list-left">
              <div class="list-left-top">
                <div class="discounts">{{ item.name }}</div>
                <div class="valid-time">
                  {{ firstFliter(item.start_time) }}
                  至
                  {{ firstFliter(item.end_time) }}
                </div>
              </div>
              <div
                class="list-left-bottom"
                :class="{ 'list-left-height': activeColor === 2 && item.msg }"
                @click.stop
              >
                <span class="title-text" v-if="activeColor === 1"
                  >使用范围：{{ item.range_str }}</span
                >
              </div>
            </div>
            <div class="list-right">
              <div class="right-content">
                <van-checkbox
                  :value="index"
                  :name="index"
                  v-if="activeColor === 1"
                  @click="selectList(index)"
                ></van-checkbox>
                <div class="money-box">
                  <Price :price="item.money" integerStyle="26"></Price>
                  <!-- <div class="money">
                    <span>￥</span>
                    <span class="money-number">{{ item.money }}</span>
                    <span class="money-number">{{ item.money }}</span>
                  </div> -->
                  <div class="overbrim-minus" v-if="item.full_money * 1 > 0">
                    满{{ item.full_money }}元可减
                  </div>
                  <div class="overbrim-minus" v-else>无门槛</div>
                </div>
              </div>
            </div>
          </li>
        </van-checkbox-group>
      </ul>
      <div class="blank-box" v-else>
        <div class="blank">
          <img src="@/assets/blank/icon@2x.png" class="blank-img" />
          <span class="blank-text">暂无优惠券</span>
        </div>
      </div>
      <div
        class="bottom-btn"
        v-if="
          result.length > 0 &&
          activeColor === 1 &&
          couponList.coupon_list.length > 0
        "
      >
        <span>
          优惠{{
            oldTotalMoney > couponList.coupon_list[result[0]].money
              ? couponList.coupon_list[result[0]].money
              : oldTotalMoney
          }}元
        </span>
        <div class="confirm-btn" @click="setCoupon()">确认使用</div>
      </div>
      <div class="bottom-btn" v-else>
        <span>优惠0.00元</span>
        <div class="confirm-btn" @click="setCoupon()">取消</div>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { intercept } from "@/common/js/utils.js";
export default {
  props: {
    oldTotalMoney: {
      default: 0,
    },
    couponList: {
      default: {
        coupon_list: [],
        un_coupon: [],
      },
    },
  },
  data() {
    return {
      show: false,
      activeColor: 0,
      result: [0],
      showDescribe: false,
      showIndex: 0,
      list: [],
    };
  },
  watch: {
    "_props.couponList": function () {
      console.log(this.couponList);
      this.setType(1);
    },
  },
  mounted() {},
  methods: {
    fun() {
      if (this.result.length <= 0) {
        this.$emit("setCoupon", -1);
      } else {
        this.$emit("setCoupon", 0);
      }
      window.removeEventListener("popstate", this.fun, false);
    },
    setCoupon() {
      this.show = false;
      if (this.activeColor === 1) {
        if (this.result.length <= 0) {
          this.$emit("setCoupon", -1);
        } else {
          this.$emit("setCoupon", this.result[0]);
        }
      }
    },

    setType(activeColor) {
      if (this.activeColor === activeColor) {
        return;
      }
      this.activeColor = activeColor;
      if (activeColor === 1) {
        this.list = this.couponList.coupon_list;
      } else {
        this.list = this.couponList.un_coupon;
      }
      intercept(this.list);
    },
    showCoupon() {
      this.show = true;
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener("popstate", this.fun, false); //false阻止默认事件
      }
    },
    unfoldBtn(index) {
      if (this.showDescribe && this.showIndex !== index) {
      } else {
        this.showDescribe = !this.showDescribe;
      }
      this.showIndex = index;
    },
    selectList(index) {
      this.result.forEach((v, k) => {
        if (v != index) {
          this.result.splice(k, 1);
        }
      });
    },
    closePopup() {
      this.show = false;
      window.removeEventListener("popstate", this.fun, false);
    },
    firstFliter(str) {
      return str.split(" ")[0];
    },
  },
};
</script>

<style lang="less" scoped>
.order-popup {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.nav {
  position: relative;
  height: 57px;
  text-align: center;
  line-height: 57px;
  background: rgb(255, 255, 255);
  font-size: 21px;
  border-bottom: 1px solid rgb(238, 238, 238);

  .arrow-left {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}

.use-tab {
  display: flex;
  height: 57px;
  background-color: #fff;

  .tab {
    flex: 1;
    position: relative;
    line-height: 57px;
    text-align: center;
    font-size: 18px;
  }

  .tab-border {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 54px;
    height: 3px;
    background-color: #ed301d;
    border-radius: 3px;
  }

  .title-color {
    color: #ed301d;
  }
}

.discounts-content {
  flex: 1;
  background-color: #f2f2f2;
  padding: 10px 13px;
  overflow: auto;

  .list {
    position: relative;
    display: flex;
    margin-bottom: 11px;
    background: url("../../../../assets/discounts/bg@2x.png") no-repeat center /
      100% 100%;

    &:last-child {
      margin-bottom: 0;
    }

    .expire-icon {
      position: absolute;
      right: 0;
      top: 0;
      width: 57px;
      height: 57px;
    }

    .shade {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.39);
      z-index: 100;

      .cause-location {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 13px;

        div {
          margin-bottom: 10px;
          font-size: 13px;

          &:nth-child(1) {
            color: #ed301d;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  .list-left {
    flex: 1;
    padding: 17px 22px 17px 12px;
  }

  .list-left-top {
    padding-bottom: 16px;
    border-bottom: 1px dashed rgb(235, 235, 235);

    .discounts {
      font-size: 17px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }

    .valid-time {
      color: #999999;
      font-size: 13px;
      margin-top: 8px;
    }
  }

  .bottom-else {
    height: 104px;
  }

  .list-left-bottom {
    padding-top: 16px;
    font-size: 12px;
    color: #666666;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .unfold-btn {
        width: 16px;
        height: 16px;
      }
    }

    .describe-content {
      display: flex;
      margin-top: 12px;

      .describe-label {
        width: 60px;
      }
    }
  }
  .list-left-height {
    height: 53px;
  }
  .list-right {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    .money-box {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .right-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      flex: 1;
      height: 100px;

      .money {
        display: flex;
        align-items: center;
        color: #ed301d;
        font-size: 12px;

        .money-number {
          font-size: 28px;
        }
      }

      .overbrim-minus {
        color: #666666;
        font-size: 13px;
      }
    }
  }
}

.bottom-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  padding: 0 8px 0 37px;
  font-size: 18px;
  color: #ed301d;

  .confirm-btn {
    width: 207px;
    height: 52px;
    text-align: center;
    line-height: 52px;
    background: linear-gradient(96deg, #f25757 0%, #ed301d 100%);
    border-radius: 52px;
    color: #fff;
    font-size: 21px;
  }
}
.blank-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    .blank-img {
      width: 221px;
      height: 144px;
    }
    .blank-text {
      text-align: center;
      margin-top: 13px;
      color: #aaaaaa;
      font-size: 17px;
    }
  }
}
</style>
